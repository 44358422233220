import React, { useCallback, useState, useRef } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Form, Input, Button, Row, Col, Spin, notification } from 'antd';
// @ts-ignore
import ImagemLogo from '../../assets/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import * as LoginActions from '../../store/modules/login/actions';
import { LoginState } from './../../interfaces';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { urlLogin } from '../../services/request/urls';

import { Layout1_2, esqueceuASenha, LayoutLogoLogin } from './GridStyle';

interface SubmitFormLogin {
    email: string;
    senha: string;
}

const Login: React.FC = () => {

    const formRef = useRef<any>(null);

    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const onFinish = useCallback((dados): void => {
        setLoading(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.post(urlLogin, dados);

                formRef.current?.resetFields();
                localStorage.setItem('@GovFacil:token', JSON.stringify(
                    result.data
                ));

                dispatch(LoginActions.loginAlterSingned());
                history.push("/usuarios");

            } catch (error) {
                setLoading(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel logar, verifique seus dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    const onFinishFailed = useCallback((errorInfo): void => {

    }, []);

    const propsState = useSelector((state: LoginState) => state.login);


    return (
        <>
            <Row gutter={24} style={{ maxHeight: "100vh", margin: 0 }} align="middle">

                <Col className="fotofundoLogin" flex={3} >
                </Col>
                <Col flex={1}>
                    <Spin spinning={loading}>
                        <Form
                            name="formLogin"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Row>
                                <Col {...LayoutLogoLogin}>
                                    <img className="logoimgMenuResponsiveLogin" src={ImagemLogo}></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...Layout1_2}>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor digite seu nome!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nome" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...Layout1_2}>
                                    <Form.Item
                                        name="senha"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor digite sua senha!',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder="Senha" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...esqueceuASenha}>
                                    <Form.Item>
                                        <a style={{ marginLeft: 7 }} className="login-form-forgot">
                                            Esqueceu a Senha?
                                    </a>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...Layout1_2}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} >
                                            Entrar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Col>
            </Row>
        </>
    );
};

export default Login;
